<template>
    <div class="container">
        <Detail v-model:detailId="detailId" :status="status" />
        <FilterBlock
            @search="search"
            :teamOptions="teamOptions"
            :submitUserOptions="submitUserOptions"
            :produceOptions="produceOptions"
        />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'workOrderCode'">
                        {{ record?.cjWorkOrderInfo?.code }}
                    </template>
                    <template v-if="column.dataIndex === 'typeDesc'">
                        {{ record?.cjWorkOrderInfo?.typeDesc }}
                    </template>
                    <template v-if="column.dataIndex === 'projectCode'">
                        {{ record?.cjWorkOrderInfo?.projectCode }}
                    </template>
                    <template v-if="column.dataIndex === 'projectName'">
                        {{ record?.cjWorkOrderInfo?.projectName }}
                    </template>
                    <template v-if="column.dataIndex === 'productName'">
                        {{ record?.cjWorkOrderInfo?.productName }}
                    </template>
                    <template v-if="column.dataIndex === 'productCode'">
                        {{ record?.cjWorkOrderInfo?.productCode }}
                    </template>
                    <template v-if="column.dataIndex === 'produceTeamName'">
                        {{ record?.cjWorkOrderInfo?.produceTeamName }}
                    </template>
                    <template v-if="column.dataIndex === 'cjWorkReportUsers'">
                        {{ record?.cjWorkReportUsers?.length }}
                    </template>
                    <template v-if="column.dataIndex === 'reportDate'">
                        {{ formateMoment(record?.productionStartTime, "YYYY-MM-DD") }}
                    </template>
                    <template v-if="column.dataIndex === 'reportTime'">
                        {{ formateMoment(record?.productionStartTime, "HH:mm") }} ~
                        {{ formateMoment(record?.productionEndTime, "HH:mm") }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                        </a-space>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import { formateMoment, showMessage } from "@/utils/common";
import { apiCJWorkReportList, apiCJWorkReportIndexData } from "@/api";

export default defineComponent({
    components: {
        FilterBlock,
        Detail,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "报工流水号",
                dataIndex: "code",
            },
            {
                title: "工单号",
                dataIndex: "cjWorkOrderCode",
            },
            {
                title: "工单类型",
                dataIndex: "typeDesc",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
            },
            {
                title: "提交人",
                dataIndex: "creatorDisplayName",
            },
            {
                title: "报工工序",
                dataIndex: "cjWorkOrderStepName",
            },
            {
                title: "完成数量",
                dataIndex: "completedCount",
            },
            {
                title: "合格数量",
                dataIndex: "qualifiedCount",
            },
            {
                title: "不合格数量",
                dataIndex: "unqualifiedCount",
            },
            {
                title: "生产人数",
                dataIndex: "cjWorkReportUsers",
                width: 100,
            },
            {
                title: "生产日期",
                dataIndex: "reportDate",
            },
            {
                title: "生产时间",
                dataIndex: "reportTime",
            },
            {
                title: "生产时长(/h)",
                dataIndex: "reportDurationHours",
            },
            {
                title: "生产总工时(/h)",
                dataIndex: "reportDurationTotalHours",
            },
            {
                title: "异常时长(/h)",
                dataIndex: "exceptionWorkingHours",
            },
            {
                title: "异常总工时(/h)",
                dataIndex: "exceptionTotalWorkingHours",
            },
            {
                title: "提交时间",
                dataIndex: "createdTime",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 100,
            },
        ];

        const state = reactive({
            status: "",
            statusOptions: [],
            viewPicVisible: false,
            teamOptions: [],
            submitUserOptions: [],
            produceOptions: [],
            detailId: null,
            auditId: null,
            params: null,
            data: [],
            loading: false,
            curLivePictureIds: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiCJWorkReportList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: "PASSED",
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const changeTabStatus = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const getIndexData = async () => {
            let res = await apiCJWorkReportIndexData();
            if (res.status === "SUCCESS") {
                state.teamOptions = res.data?.teamCodeAndNameCandidate;
                state.submitUserOptions = res.data?.creatorIdAndNameCandidate;
                state.produceOptions = res.data?.stepCodeAndNameCandidate;
                state.statusOptions = res.data?.statusList;
                if (state.statusOptions?.length) {
                    state.status = state.statusOptions[0].key;
                    getTabelData();
                }
            }
        };
        getIndexData();

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            changeTabStatus,
            getTabelData,
            changeTabStatus,
            formateMoment,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
