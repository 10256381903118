<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="code" placeholder="请输入报工流水号" />
            <a-input v-model:value="workOrderCode" placeholder="请输入工单号" />
            <Select
                v-model:value="teamCode"
                :options="teamOptions"
                labelKey="value"
                valueKey="key"
                placeholder="请选择生产班组"
            />
            <Select
                v-model:value="creatorId"
                :options="submitUserOptions"
                labelKey="value"
                valueKey="key"
                placeholder="请选择提交人"
            />
            <Select
                v-model:value="stepCode"
                :options="produceOptions"
                labelKey="value"
                valueKey="key"
                placeholder="请选择工序"
            />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
export default defineComponent({
    props: ["teamOptions", "submitUserOptions", "produceOptions"],
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const params = reactive({
            code: undefined,
            workOrderCode: undefined,
            teamCode: undefined,
            creatorId: undefined,
            stepCode: undefined,
        });

        const reset = () => {
            params.code = undefined;
            params.workOrderCode = undefined;
            params.teamCode = undefined;
            params.creatorId = undefined;
            params.stepCode = undefined;
            search();
        };

        const search = () => {
            let { code, workOrderCode } = params;
            params.code = code?.trim();
            params.workOrderCode = workOrderCode?.trim();
            emit("search", params);
        };

        return {
            ...toRefs(params),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
